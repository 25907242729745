import Chart from 'chart.js/auto';
import { Colors } from 'chart.js';

Chart.register(Colors);

import { getRelativePosition } from 'chart.js/helpers';


new Chart(document.getElementById("entries"), {
  type: 'doughnut',
  data: {
    // labels: ["Verifiziert", "Nicht verifiziert"],
    datasets: [
      {
        // label: "Anzahl Fahrzeuge",
        backgroundColor: ["#388e3c", "#ffa000"],
        borderWidth: 0,
        borderColor: '#373737',
        data: [5414,77]
      }
    ]
  },
  options: {
    animations: {
      tension: {
        duration: 1000,
        easing: 'linear',
        from: 100,
        to: 0,
        loop: true
      }
    },
    title: {
      display: false,
    }
  }
});

(async function() {
  const nationsMostUsersData = [
    { nation: "USA", count: 2510 },
    { nation: "Germany", count: 1797 },
    { nation: "England", count: 577 },
    { nation: "Belgium", count: 241 },
    { nation: "France", count: 225 },
    // { nation: "Australien", count: 188 },
    // { nation: "Schweiz", count: 160 },
    // { nation: "Italien", count: 139 },
    // { nation: "Niederlande", count: 131 },
    // { nation: "Kanada", count: 129 },
    // { nation: "Österreich", count: 121 },
  ];

  new Chart(
    document.getElementById('nationsMostUsers'),
    {
      type: 'bar',
      data: {
        labels: nationsMostUsersData.map(row => row.nation),
        datasets: [
          {
            // label: 'Top 11 angemeldete Nationen',
            // fill: false,
            borderWidth: 1,
            backgroundColor: "#FFF",
            borderRadius: 5,
            borderColor: '#373737',
            borderWidth: 0,
            data: nationsMostUsersData.map(row => row.count)
          }
        ]
      },
      options: {
        // responsive: true,
        plugins: {
          legend: {
            display: false
          }
        },
        animations: {
          tension: {
            duration: 1000,
            easing: 'linear',
            from: 100,
            to: 0,
            loop: true
          }
        },
        scales: {
          y: { // defining min and max so hiding the dataset does not change scale range
            min: 0,
            max: 2550,
            ticks: {
              color: "white"
            }
          },
          x: {
            ticks: {
              color: "white"
            }
          }
        }
      },
    }
  );
})();

(async function() {
  const entriesPerYearData = [
    { year: 2008, count: 450 },
    { year: 2009, count: 393 },
    { year: 2010, count: 222 },
    { year: 2011, count: 388 },
    { year: 2012, count: 327 },
    { year: 2013, count: 198 },
    { year: 2014, count: 223 },
    { year: 2015, count: 242 },
    { year: 2016, count: 210 },
    { year: 2017, count: 151 },
    { year: 2018, count: 154 },
    { year: 2019, count: 109 },
    { year: 2020, count: 120 },
    { year: 2021, count: 127 },
    { year: 2022, count: 96 },
    { year: 2023, count: 68 },
  ];

  new Chart(
    document.getElementById('entriesPerYear'),
    {
      type: 'bar',
      data: {
        labels: entriesPerYearData.map(row => row.year),
        datasets: [
          {
            // label: 'Neue Fahrzeug-Einträge inkl. Halter',
            // fill: false,
            borderWidth: 1,
            backgroundColor: "#0288d1",
            // backgroundColor: Utils.transparentize('#373737', 0.5),
            borderColor: '#373737',
            borderRadius: 5,
            borderWidth: 0,
            data: entriesPerYearData.map(row => row.count)
          }
        ]
      },
      options: {
        plugins: {
          legend: {
            display: false
          }
        },
        animations: {
          tension: {
            duration: 1000,
            easing: 'linear',
            from: 100,
            to: 0,
            loop: true
          }
        },
        scales: {
          y: { // defining min and max so hiding the dataset does not change scale range
            min: 0,
            max: 470,
            ticks: {
              color: "black"
            }
          },
          x: {
            ticks: {
              color: "black"
            }
          }
        }
      },
    }
  );
})();